<template>
  <div class="columns" id="requestList">
    <div class="column is-12">
      <v-main>
        <v-container fluid>

          <div class="back-button text-right mb-6">
            <v-btn icon @click="goBack">
              <v-icon color="grey darken-2">mdi-chevron-down</v-icon>
            </v-btn>
          </div>

          <v-container v-if="!activeOrder || activeOrder.length === 0 || !activeOrder ">
            <v-row justify="center" align="center">
              <v-col cols="12" md="8">
                <div class="text-center">
                  <h2 class="title is-3 mb-2">У вас пока нет активных заказов</h2>
                  <b-button @click="$router.push('/activity-feed')" type="is-success is-light">
                    Назад
                  </b-button>
                </div>
              </v-col>
            </v-row>
          </v-container>

          <v-container v-else>
            <v-row>
              <v-card class="w-100 mb-3 no-gradient-block-1">
                <v-card-text class="pb-0">
                  <div class="pa-3">
                    <v-row>
                      <v-col class="pa-0">
                        <h1 class="title is-6 ml-3">{{ dateText(activeOrder.time) }}</h1>
                      </v-col>
                      <v-col class="pa-0 tags_col">
                        <b-taglist>
                          <b-tag rounded v-if="activeOrder.status === 'created'" type="is-primary">Создан</b-tag>
                          <b-tag rounded v-else-if="activeOrder.status === 'agreed'" type="is-info">Подтвержден</b-tag>
                          <b-tag rounded v-else-if="activeOrder.status === 'completed'" type="is-success">Доставлен</b-tag>
                          <b-tag rounded v-else-if="activeOrder.status === 'in_progress'" type="is-warning">В пути</b-tag>
                          <b-tag rounded v-else-if="activeOrder.status === 'cancelled'" type="is-danger">Отменен</b-tag>
                        </b-taglist>
                      </v-col>
                    </v-row>

                    <v-row class="py-2">
                      <v-col cols="2" class="column is-3">
                        <img src="/assets/road.svg">
                      </v-col>
                      <v-col cols="10" class="column is-9 order-route">
                        <v-row>
                          <h1 class="title is-5">{{ activeOrder.from_location }}</h1>
                        </v-row>
                        <v-row>
                          <h1 class="title is-5 mt-2">{{ activeOrder.to_location }}</h1>
                        </v-row>
                      </v-col>
                    </v-row>

                    <div v-if="activeOrder.type_vehicle">
                      <h3 class="title pt-2 is-6">Тип транспорта</h3>
                      <p>{{ wrapperTransportType() }}</p>
                    </div>

                    <div v-if="activeOrder.car_id">
                      <h3 class="title pt-2 is-6">Машина</h3>
                      <p>{{ wrapperCar() }}</p>
                    </div>

                    <div v-if="activeOrder.comment && activeOrder.comment !== ''">
                      <h3 class="title is-6">Комментарий</h3>
                      <p class="text-pre-wrap">{{activeOrder.comment}}</p>
                    </div>

                  </div>
                </v-card-text>
                <v-card-actions class="py-0 pb-3 flex justify-center">
                  <b-button
                    class="is-danger"
                    @click="cancelOrder"
                    type="is-text">
                    Отменить заказ
                  </b-button>
                </v-card-actions>
              </v-card>
            </v-row>

            <v-row class="mb-4 mt-6" v-if="counterOffersOfOrders !== 0
            && (activeOrder.status === 'agreed' || activeOrder.status === 'in_progress')">
              <b-taglist>
                <b-tag rounded type="is-success is-medium">Перевозчик выбран</b-tag>
              </b-taglist>
            </v-row>

            <v-row v-else>
              <h3 v-if="counterOffersOfOrders !== 0" class="title ml-3 mt-3 mb-3">Предложения</h3>
              <h3 v-else class="title ml-3 mt-3">Предложений пока нет</h3>
            </v-row>

            <v-row v-for="(item, index) in offers" :key="item.id">
              <v-card class="w-100 mb-3" :class="'w-100 mb-3 no-gradient-block-' + (index % 4 + 1)">
                  <v-card-text class="pb-0">
                      <div class="pa-3 pb-0">
                        <v-row>
                          <h1 class="title is-6 ml-3">{{ dateText(item.time) }}</h1>
                        </v-row>
                        <v-row class="mb-2">
                          <h1 class="title is-6 ml-3" v-if="item.transporter && item.transporter.name">
                            {{ item.transporter.name }}
                          </h1>
                        </v-row>

                        <h3 class="title is-6">Цена</h3>
                        <p>{{ item.price }}</p>

                        <div @click="toggleCollapse(index)" class="cursor-pointer">
                          <span>Дополнительная информация</span>
                          <v-icon>{{ showDetails[index] ? 'mdi-menu-up' : 'mdi-menu-down' }}</v-icon>
                        </div>

                        <!-- Блок с дополнительной информацией, который показывается/скрывается по клику на кнопку -->
                        <div v-if="showDetails[index]">

                          <h3 class="title is-6">Комментарий</h3>
                          <p class="text-pre-wrap">{{item.comment}}</p>
                        </div>

                      </div>
                  </v-card-text>
                  <v-card-actions class="pb-3">
                    <b-button type="is-text"
                              v-if="activeOrder.status !== 'agreed' && activeOrder.status !== 'in_progress'"
                              @click="confirmToOffer(item.id)">СОГЛАСИТЬСЯ</b-button>

                    <b-button type="is-text"
                              v-else
                              @click="goToContactsPartner()">КОНТАКТЫ</b-button>
                  </v-card-actions>
              </v-card>

            </v-row>
          </v-container>

        </v-container>
      </v-main>
    </div>
  </div>

</template>

<script>

export default {
  name: 'RequestList',
  data() {
    return {
      variant: 'outlined',
      // Массив для отслеживания состояния сворачивания карточек
      showDetails: [],
      blocks: [
        { text: 'Мотоцикл', image: '/assets/scooter.svg' },
        { text: 'Автомобиль', image: '/assets/car.svg' },
        { text: 'Грузовик', image: '/assets/big-car.svg' },
        { text: 'Спецтехника', image: '/assets/special_equipment.svg' }
      ],
    };
  },
  created() {
    this.requestInfo();
  },
  computed: {
    activeOrder() {
      return this.$store.getters.ACTIVE_ORDER;
    },
    counterOffersOfOrders() {
      return this.$store.getters.ACTIVE_ORDER_COUNTER;
    },
    offers() {
      return this.$store.getters.OFFERS;
    },
  },
  methods: {
    wrapperCar() {
      if (this.activeOrder.car.mark && this.activeOrder.car.model) {
        return this.activeOrder.car.mark + ' ' + this.activeOrder.car.model
      } else {
        return '';
      }
    },
    wrapperTransportType() {
      if (this.activeOrder.type_vehicle) {
        return this.blocks[this.activeOrder.type_vehicle].text
      } else {
        return '';
      }
    },
    goToContactsPartner() {
      this.$router.push('/order/contacts-partner/' + this.activeOrder.id);
    },
    formatText(text) {
      let text_formated = text ? text.replace(/\n/g, '<br>') : '';
      console.log('Текст: ' + text_formated);
      return text_formated;
    },
    dateText(date) {
      return this.$store.getters.TEXT_DATE(date);
    },
    goBack() {
      this.$router.push('/activity-feed');
    },
    toggleCollapse(index) {
      // Изменяем состояние сворачивания для соответствующей карточки
      this.$set(this.showDetails, index, !this.showDetails[index]);
    },
    confirmToOffer(id) {
      this.$store.dispatch("CONFIRM_TO_OFFER", id)
        .then(() => {
          this.$buefy.toast.open({
            message: 'Перевозчик определён',
            type: 'is-success'
          });
          this.requestInfo();
        })
        .catch(() => {
          this.$buefy.toast.open({
            message: 'Ошибка',
            type: 'is-danger'
          });
        });
    },
    requestInfo () {
      this.$store.dispatch('GET_ACTIVE_ORDER').then(() => {
        this.$store.dispatch('GET_OFFERS', this.activeOrder.id);
      });
    },
    cancelOrder() {
      this.$router.push('/cancellation-of-order');
    }
  },
};
</script>

<style>
#requestList .title.is-6 {
  margin-bottom: 4px;
}

.title.is-6 {
  font-size: 1.4em !important;
  margin-bottom: 0 !important;
}

p {
  font-size: 19px;
}
</style>
